import { t } from '@ping/helpers';
import { isValidAddress } from '@ping/vendors';

export const validateAddress = (address: string, assetId: string): string => {
  let result = '';

  if (!address) {
    result = t('Please enter beneficiary address');
  } else if (!isValidAddress({ address, assetId })) {
    result = t('Invalid address');
  }

  return result;
};
