import { createContactCrypto, updateContactCrypto } from '@ping/api';
import { t } from '@ping/helpers';
import { AssetSelect, Button, Text, Toast } from '@ping/uikit';
import { useEffect, useRef, useState } from 'react';
import { validateAddress } from './validation.helper';
import clsx from 'clsx';

import style from './style.module.scss';

type IContactCryptoFields = {
  accountName?: string;
  address: string;
  assetId: string;
};

interface IEditModeProps {
  mode: 'edit';
  addressID: string;
  defaultValues: IContactCryptoFields;
}

interface ICreateModeProps {
  mode?: 'create';
  addressID?: never;
  defaultValues?: IContactCryptoFields;
}

type IContactCryptoProps = {
  finishCallback: (result: IContactCryptoFields) => void;
} & (ICreateModeProps | IEditModeProps);

interface IContactCryptoFormErrors {
  accountName?: string;
  address?: string;
  assetId?: string;
}

export const ContactCrypto = (props: IContactCryptoProps) => {
  const { finishCallback, mode = 'create' } = props;
  const addressID = mode === 'edit' ? props?.addressID : null;
  const defaultValues = props?.defaultValues;
  const [address, setAddress] = useState(defaultValues?.address || '');
  const [accountName, setAccountName] = useState(defaultValues?.accountName || '');
  const [assetId, setAssetId] = useState(defaultValues?.assetId || '');
  const isFormSubmitted = useRef<boolean>();
  const [formErrors, setFormErrors] = useState<IContactCryptoFormErrors>({});

  const hasError = !address || !assetId || !accountName;
  const isEditMode = mode === 'edit';

  useEffect(() => {
    setAssetId(defaultValues?.assetId || '');
    setAddress(defaultValues?.address || '');
  }, [defaultValues]);

  useEffect(() => {
    setAddress(defaultValues?.address || '');
  }, [assetId]);

  useEffect(() => {
    if (isFormSubmitted.current) {
      handleSetErrors();
    }
    if (formErrors.assetId) {
      handleSetErrors();
    }
    if (formErrors.address) {
      handleSetErrors();
    }
  }, [address, assetId, accountName]);

  const handleSetErrors = () => {
    setFormErrors({
      address: validateAddress(address, assetId),
      assetId: !assetId && t('Please select the currency'),
      accountName: !accountName.trim() && t('Please enter the identifier'),
    });
  };

  const onContactCryptoSuccess = () => {
    finishCallback({ address, assetId, accountName });
    isFormSubmitted.current = false;
    setAddress('');
    setAccountName('');
    Toast.success({ title: 'The address has been successfully saved' });
  };

  const onContactCryptoFail = error => {
    error?.response?.data?.errors?.forEach(errorItem => {
      Toast.error({ title: errorItem?.message });
    });
  };

  const handleContactCryptoClick = event => {
    event.preventDefault();
    event.stopPropagation();

    isFormSubmitted.current = true;
    if (hasError || formErrors.address || formErrors.accountName) {
      handleSetErrors();
    } else {
      if (isEditMode) {
        updateContactCrypto(addressID, { wallet: address, assetId: assetId, accountName: accountName })
          .then(onContactCryptoSuccess)
          .catch(onContactCryptoFail);
      } else {
        createContactCrypto({ wallet: address, assetId: assetId, accountName: accountName })
          .then(onContactCryptoSuccess)
          .catch(onContactCryptoFail);
      }
    }
  };
  const handleAddressFocusLost = () => {
    handleSetErrors();
  };

  return (
    <form className={style['contactCrypto']} onSubmit={handleContactCryptoClick}>
      <div className={style['contactCrypto__block']}>
        <AssetSelect
          label={t('Currency')}
          assetId={assetId}
          onChange={setAssetId}
          filterFn={asset => asset.can_withdraw}
        />

        {formErrors.assetId && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.assetId}
          </Text>
        )}
      </div>
      <div className={style['contactCrypto__block']}>
        <Text body='regular' className={style['contactCrypto__text']}>
          {t('Identifier')}
        </Text>
        <input
          type='text'
          placeholder={t('Address nickname')}
          value={accountName}
          onChange={e => setAccountName(e.target.value)}
          className={clsx(style['contactCrypto__input'], `${formErrors.accountName ? 'has-input-error' : ''}`)}
        />
        {formErrors.accountName && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.accountName}
          </Text>
        )}
      </div>
      <div className={style['contactCrypto__block']}>
        <Text body='regular' className={style['contactCrypto__text']}>
          {t('Beneficiary address')}
        </Text>
        <input
          type='text'
          placeholder={t('Beneficiary address')}
          value={address}
          onChange={e => setAddress(e.target.value)}
          className={clsx(style['contactCrypto__input'], `${formErrors.address ? 'has-input-error' : ''}`)}
          onBlur={handleAddressFocusLost}
        />
        {formErrors.address && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.address}
          </Text>
        )}
      </div>

      <Button type='secondary' design='general' size='extra-large' uppercase htmlType='submit'>
        {t('Save')}
      </Button>
    </form>
  );
};
