import clsx from 'clsx';
import { useRef } from 'react';
import { useSearchField, VisuallyHidden } from 'react-aria';
import { useSearchFieldState } from 'react-stately';
import SearchIcon from '@ping/assets/Icon/search.svg';
import FieldGroup from '../FieldGroup';
import { PingInput } from '../PingInput';
import type IReactAria from 'react-aria';

import style from './style.module.scss';

interface ISearchFieldProps extends IReactAria.AriaSearchFieldProps, ICustomizable {
  name?: string;
  disallowedKeysList?: string[];
}

export const SearchField = (props: ISearchFieldProps) => {
  const state = useSearchFieldState(props);
  const inputRef = useRef<HTMLInputElement>();
  const { labelProps, inputProps } = useSearchField(props, state, inputRef);

  return (
    <FieldGroup className={style['search-field']}>
      <VisuallyHidden>
        <FieldGroup.Label {...labelProps}>{props.label}</FieldGroup.Label>
      </VisuallyHidden>

      <FieldGroup.Control className={clsx(style['search-field__control'], props.className)}>
        <PingInput
          {...inputProps}
          Icon={<SearchIcon />}
          className={style['search-field__input']}
          name={props.name}
          ref={inputRef}
          disallowedKeysList={props.disallowedKeysList}
        />
      </FieldGroup.Control>
    </FieldGroup>
  );
};
