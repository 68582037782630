import { useGetAllAsset } from '@ping/api';
import { t } from '@ping/helpers';
import { CoinItem, SearchField } from '@ping/uikit';
import { Select } from '@ping/uikit/SelectA11Y';
import { alwaysTrue } from '@ping/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import style from './style.module.scss';

import type { AssetResponse } from '@ping/api';
import { type PopoverPlacement } from '../PrimitivePopover/PrimitivePopoverRoot';

const ONE_DAY = 1 * 60 * 60 * 24 * 1000;

interface IAssetSelectProps extends ICustomizable {
  assetId?: string;
  label?: string;
  name?: string;
  isDisabled?: boolean;
  magnet?: IMagnet;
  boundaryRef?: React.MutableRefObject<HTMLElement>;
  onChange?(asset: string): void;
  onOpenChange?: (isOpen: boolean) => void;
  onPanelPlacementChange?: (placement: PopoverPlacement) => void;
  /**
   * A function that is used to filter the asset options.
   */
  filterFn?(asset: AssetResponse, index: number, array: AssetResponse[]): boolean;
}

export const AssetSelect = (props: IAssetSelectProps) => {
  const [items, setItems] = useState<AssetResponse[]>([]);

  const { data: assets, isLoading: isAssetsLoading } = useGetAllAsset({
    query: {
      staleTime: ONE_DAY,
      select: ({ data: allAssets }) => {
        return allAssets.filter(props.filterFn ?? alwaysTrue) || [];
      },
    },
  });

  useEffect(() => filterViewItems(''), [isAssetsLoading, props.filterFn]);

  const filterViewItems = (value: string) => {
    if (isAssetsLoading || !assets) {
      return;
    }

    const text = value.trim().toLowerCase();
    const items = assets.filter(
      asset => asset.asset_name.toLowerCase().includes(text) || asset.id.toLowerCase().includes(text)
    );

    setItems(items);
  };

  const handleOnSelectionChange = (asset: string) => {
    filterViewItems('');
    props.onChange?.(asset);
  };

  return (
    <Select
      className={clsx(style['asset-select'], props.className)}
      items={items}
      isLoading={isAssetsLoading}
      isDisabled={props.isDisabled}
      magnet={props.magnet}
      label={props.label}
      name={props.name}
      boundaryRef={props.boundaryRef}
      defaultSelectedKey={props.assetId}
      onSelectionChange={handleOnSelectionChange}
      onOpenChange={isOpen => {
        props.onOpenChange?.(isOpen);
        !isOpen && filterViewItems('');
      }}
      onPanelPlacementChange={props.onPanelPlacementChange}
      emptyState={t('No available asset')}
      buttonContent={
        props.assetId ? (
          <CoinItem className={style['asset-select__selection']} coinName={props.assetId?.toUpperCase()} />
        ) : (
          t('Select')
        )
      }
      topElement={
        <>
          <SearchField
            className={style['asset-select__search']}
            placeholder={t('Search')}
            label={t('Search through assets')}
            onChange={filterViewItems}
            disallowedKeysList={[' ', 'Space']}
          />
          {!items.length && !isAssetsLoading && <p className={style['asset-select__not-found']}>{t('Not Found')}</p>}
        </>
      }
    >
      {asset => (
        <Select.Item
          key={asset.id}
          textValue={asset.asset_name.toUpperCase()}
          aria-label={asset.asset_name.toUpperCase()}
        >
          <CoinItem className='cursor-pointer' coinName={asset.id.toUpperCase()} />
        </Select.Item>
      )}
    </Select>
  );
};
