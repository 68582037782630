import { clsx } from 'clsx';

import style from './style.module.scss';

interface IUserLayoutContentProps extends ICustomizable {
  children: React.ReactNode;
}

export const UserLayoutContent = (props: IUserLayoutContentProps) => (
  <div className={clsx(style['user-layout-content'], props.className)}>{props.children}</div>
);
