import ican from '@cryptohub/ican';
import { ENVIRONMENT } from '@ping/configs';
import multicoin from 'multicoin-address-validator';

const CORE_CURRENCIES = new Set(['xcb', 'xce', 'xab', 'ctn', 'cts', 'ceur', 'cusd']);
const NETWORK_BASED_ON_ENV = new Map<typeof ENVIRONMENT, string>()
  .set('develop', 'both')
  .set('staging', 'both')
  .set('testnet', 'both')
  .set('production', 'prod');

interface IIsValidAddressInput {
  address: string;
  assetId: string;
}

/**
 * It checks if an address is valid based on the asset ID and network environment.
 * @param {IIsValidAddressInput} input - The input parameter is an object with the following
 * properties:
 * - address
 * - assetId
 */
export const isValidAddress = (input: IIsValidAddressInput) => {
  let output = false;

  if (CORE_CURRENCIES.has(input.assetId.toLowerCase())) {
    output = ican.isValid(input.address);
  } else {
    output = multicoin.validate(input.address, input.assetId.toLowerCase(), NETWORK_BASED_ON_ENV.get(ENVIRONMENT));
  }

  // TODO: for testing 86bwhe0zg
  console.log('isValidAddress', output, input, ENVIRONMENT, NETWORK_BASED_ON_ENV.get(ENVIRONMENT));

  return output;
};
