import { clsx } from 'clsx';

import style from './style.module.scss';

interface IUserLayoutSubtitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const UserLayoutSubtitle = (props: IUserLayoutSubtitleProps) => (
  <div className={clsx(style['user-layout-subtitle'], props.className)}>{props.children}</div>
);
