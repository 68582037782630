import { clsx } from 'clsx';

import { SidebarItem } from './SidebarItem';
import { SidebarSection } from './SidebarSection';
import style from './style.module.scss';

interface ISidebarProps extends ICustomizable {
  children: React.ReactNode;
}

export const Sidebar = (props: ISidebarProps) => (
  <aside className={clsx(style['sidebar'], props.className)}>{props.children}</aside>
);

Sidebar.Item = SidebarItem;
Sidebar.Section = SidebarSection;
