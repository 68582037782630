import { clsx } from 'clsx';

import style from './style.module.scss';

interface IUserLayoutDescriptionProps extends ICustomizable {
  children: React.ReactNode;
}

export const UserLayoutDescription = (props: IUserLayoutDescriptionProps) => (
  <p className={clsx(style['user-layout-description'], props.className)}>{props.children}</p>
);
