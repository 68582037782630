import HistoryIcon from '@ping/assets/Icon/history.svg';
import LogoutIcon from '@ping/assets/Icon/logout.svg';
import PortfolioIcon from '@ping/assets/Icon/portfolio.svg';
import ReferralsIcon from '@ping/assets/Icon/referrals.svg';
import SettingsIcon from '@ping/assets/Icon/settings.svg';
import SupportIcon from '@ping/assets/Icon/support.svg';
import { Page } from '@ping/components';
import { signoutReset, t } from '@ping/helpers';
import { isUserActivityStatusActiveSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { Sidebar } from '@ping/uikit';
import { clsx } from 'clsx';

import style from './style.module.scss';
import { UserLayoutContent } from './UserLayoutContent';
import { UserLayoutDescription } from './UserLayoutDescription';
import { UserLayoutSubtitle } from './UserLayoutSubtitle';
import { UserLayoutTitle } from './UserLayoutTitle';

interface IUserLayoutProps extends ICustomizable {
  children: React.ReactNode;
}

export const UserLayout = (props: IUserLayoutProps) => {
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);

  return (
    <Page className={style['user-layout']} private>
      <Sidebar className={style['sidebar']}>
        <Sidebar.Section>
          <Sidebar.Item path='/user/portfolio'>
            <PortfolioIcon />
            <span>{t('Portfolio')}</span>
          </Sidebar.Item>
          <Sidebar.Item path='/user/history' isDisabled={!isUserActivityStatusActive}>
            <HistoryIcon />
            <span>{t('History')}</span>
          </Sidebar.Item>
          <Sidebar.Item path='/user/referrals' isDisabled={!isUserActivityStatusActive}>
            <ReferralsIcon />
            <span>{t('Referrals')}</span>
          </Sidebar.Item>
          <Sidebar.Item path='/user/settings' isDisabled={!isUserActivityStatusActive}>
            <SettingsIcon />
            <span>{t('Settings')}</span>
          </Sidebar.Item>
        </Sidebar.Section>

        <Sidebar.Section className={style['second-sidebar']}>
          <Sidebar.Item path='/user/support' isDisabled={!isUserActivityStatusActive}>
            <SupportIcon />
            <span>{t('Support')}</span>
          </Sidebar.Item>
          <Sidebar.Item className={style['logout']} path='/' onPress={signoutReset}>
            <LogoutIcon />
            <span>{t('Logout')}</span>
          </Sidebar.Item>
        </Sidebar.Section>
      </Sidebar>

      <section className={clsx(style['user-layout__section'], props.className)}>{props.children}</section>
    </Page>
  );
};

// TODO: 1. make it a general component to use them in admin section as well
// TODO: 2. come up with a general name maybe: "dashboard", "panel" or "layout" itself
UserLayout.Title = UserLayoutTitle;
UserLayout.Subtitle = UserLayoutSubtitle;
UserLayout.Description = UserLayoutDescription;
UserLayout.Content = UserLayoutContent;
