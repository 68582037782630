import { clsx } from 'clsx';

import style from './style.module.scss';

interface ISidebarSectionProps extends ICustomizable {
  children: React.ReactNode;
}

export const SidebarSection = (props: ISidebarSectionProps) => (
  <ul className={clsx(style['sidebar-section'], props.className)}>{props.children}</ul>
);
